@import '@/style/theme.less';

@pkg: fle-layout-header-menu;

.@{pkg} {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  &-name {
    color: #333;
    white-space: nowrap;
  }

  &-description {
    color: #777;
    font-size: 12px;
  }

  &-item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    line-height: 1.5;
    padding: 0 16px;
    cursor: pointer;

    &.active,
    &:hover {
      .@{pkg}-name {
        color: @primary-color;
      }
    }
  }
}

@primary-color: #2c68ff;@shadow-1-down: 0 6px 16px -8px rgba(0, 0, 0, 0.08), 0 9px 28px 0 rgba(0, 0, 0, 0.05),
  0 12px 48px 16px rgba(0, 0, 0, 0.03);@screen-xs: 480px;@screen-xs-min: @screen-xs;@screen-sm: 576px;@screen-sm-min: @screen-sm;@screen-md: 768px;@screen-md-min: @screen-md;@screen-lg: 992px;@screen-lg-min: @screen-lg;@screen-xl: 1200px;@screen-xl-min: @screen-xl;@screen-xxl: 1600px;@screen-xxl-min: @screen-xxl;