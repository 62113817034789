.floating-button-container {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 999;
  // height: 100vh;
  /* 让容器撑开全屏，这样拖动不会受限于容器大小 */
}

.floating-button {
  position: fixed;
  width: 40px;
  bottom: 18px;
  right: 18px;
  transform: translate(0, 0);
  // transition: transform 0.1s ease;
  cursor: pointer;
  z-index: 999;


  .floating-button-header {
    position: relative;
    width: 58px;
    height: 66px;
    left: -10px;
    bottom: -22px;
    -webkit-user-drag: none;
    overflow-clip-margin: content-box;
    overflow: initial !important;
    cursor: grab;
  }

  .floating-button-items-wrapper {
    position: relative;
    background: #2C6BFF;
    box-shadow: 0px 0px 8px 0px #2C6BFF;
    border-radius: 8px;
    z-index: 10;

    .floating-button-item {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 40px;
      font-size: 24px;
      border-bottom: 1px solid #1F54D1;
      margin: 0 auto;

      &:last-child {
        border: none;
      }
    }
  }
}

.floating-help-wrapper {
  position: fixed;
  box-sizing: border-box;
  bottom: 175px;
  right: 18px;
  width: 150px;
  min-height: 350px;
  z-index: 999;
  cursor: pointer;

  .floating-help-header {
    position: relative;
    width: 58px;
    height: 66px;
    left: 50%;
    bottom: -22px;
    margin-left: -29px;
    -webkit-user-drag: none;
    overflow-clip-margin: content-box;
    overflow: initial !important;
    cursor: pointer;
  }

  .floating-help-close {
    position: absolute;
    top: 35px;
    right: 0;
    width: 24px;
    height: 24px;
    font-size: 24px;
    z-index: 999;
    cursor: pointer;
  }

  .floating-help-main {
    background: linear-gradient(360deg, #2C6BFF 0%, #2C6BFF 100%);
    padding: 7px 8px;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    position: relative;
    z-index: 10;

    .floating-help-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 16px;
      color: #FFFFFF;
      margin-bottom: 8px;
      cursor: pointer;

      .left {
        display: flex;
        align-items: center;

        .floating-help-title-text {
          height: 17px;
          font-size: 12px;
          color: #FFFFFF;
          line-height: 17px;
          margin-left: 4px;
        }
      }
    }

    .floating-help-items {
      box-sizing: border-box;
      height: 310px;
      padding: 8px;
      padding-right: 0;
      background: #FFFFFF;
      border-radius: 4px;
      overflow: hidden;
      font-size: 12px;

      .floating-help-item {
        margin-bottom: 8px;
        color: #333333;
        line-height: 17px;
        word-break: break-all;
        margin-right: 8px;
        cursor: pointer;

        &:hover {
          color: #2C6BFF;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      .active {
        color: #2C6BFF;
      }
    }
  }
}

@primary-color: #2c68ff;@shadow-1-down: 0 6px 16px -8px rgba(0, 0, 0, 0.08), 0 9px 28px 0 rgba(0, 0, 0, 0.05),
  0 12px 48px 16px rgba(0, 0, 0, 0.03);@screen-xs: 480px;@screen-xs-min: @screen-xs;@screen-sm: 576px;@screen-sm-min: @screen-sm;@screen-md: 768px;@screen-md-min: @screen-md;@screen-lg: 992px;@screen-lg-min: @screen-lg;@screen-xl: 1200px;@screen-xl-min: @screen-xl;@screen-xxl: 1600px;@screen-xxl-min: @screen-xxl;