@import '@/style/theme.less';

@pkg: fle-layout-header-left-content;

.@{pkg} {
  display: flex;
  align-items: center;

  .fle-layout-header-action {
    margin-right: 24px;
    width: 40px;
    height: 40px;
    background-color: #f2f6fe;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;

    .anticon {
      color: rgba(44, 107, 255, 0.59);
      font-size: 24px;
    }
  }

  .fle-layout-header-logo {
    margin-right: 24px;
    height: 28px;
    display: flex;
    align-items: center;
    white-space: nowrap;

    img {
      // width: 100%;
      height: 100%;
    }

    span {
      color: #333;
      font-size: 14px;
      margin-left: 8px;
    }
  }
}

@primary-color: #2c68ff;@shadow-1-down: 0 6px 16px -8px rgba(0, 0, 0, 0.08), 0 9px 28px 0 rgba(0, 0, 0, 0.05),
  0 12px 48px 16px rgba(0, 0, 0, 0.03);@screen-xs: 480px;@screen-xs-min: @screen-xs;@screen-sm: 576px;@screen-sm-min: @screen-sm;@screen-md: 768px;@screen-md-min: @screen-md;@screen-lg: 992px;@screen-lg-min: @screen-lg;@screen-xl: 1200px;@screen-xl-min: @screen-xl;@screen-xxl: 1600px;@screen-xxl-min: @screen-xxl;