.fx__layout-loading {
  position: absolute;
  top: 36px;
  left: 0;
  height: calc(100vh - 56px - 36px) !important;
  width: 100% !important;
  min-width: 1232px;
  background-color: #F3F5F9;

  .fle-spin {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 1740px;
    width: 100%;
    height: 100%;
    margin: 16px auto;
  }
}

@primary-color: #2c68ff;@shadow-1-down: 0 6px 16px -8px rgba(0, 0, 0, 0.08), 0 9px 28px 0 rgba(0, 0, 0, 0.05),
  0 12px 48px 16px rgba(0, 0, 0, 0.03);@screen-xs: 480px;@screen-xs-min: @screen-xs;@screen-sm: 576px;@screen-sm-min: @screen-sm;@screen-md: 768px;@screen-md-min: @screen-md;@screen-lg: 992px;@screen-lg-min: @screen-lg;@screen-xl: 1200px;@screen-xl-min: @screen-xl;@screen-xxl: 1600px;@screen-xxl-min: @screen-xxl;