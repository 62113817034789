@import '@/style/theme.less';

.collapsable-wrap {
  padding: 8px 8px 40px;
}

.pannel {
  width: 100%;
  border-bottom: 1px solid #f2f3f5;

  &:last-child {
    border-bottom: none;
  }
}

.heading {
  line-height: 36px;
  padding: 0 8px;
  margin-top: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;

  .heading-title {
    user-select: none;
    padding-left: 8px;
  }
}

.heading-single-title {
  user-select: none;
  padding-left: 32px;

  &:hover {
    color: @primary-color;
  }

  &.active {
    color: @primary-color;
    background-color: #f1f6fd;
  }
}

.content {
  overflow: hidden;
}

.collapsable {
  &-panel {
    user-select: none;
    &-wrap {
      padding-bottom: 8px;
    }
    &-item {
      user-select: none;
      cursor: pointer;
      line-height: 36px;
      padding-left: 32px;

      &:hover {
        color: @primary-color;
      }

      &.active {
        color: @primary-color;
        background-color: #f1f6fd;
      }
    }
  }
}

@primary-color: #2c68ff;@shadow-1-down: 0 6px 16px -8px rgba(0, 0, 0, 0.08), 0 9px 28px 0 rgba(0, 0, 0, 0.05),
  0 12px 48px 16px rgba(0, 0, 0, 0.03);@screen-xs: 480px;@screen-xs-min: @screen-xs;@screen-sm: 576px;@screen-sm-min: @screen-sm;@screen-md: 768px;@screen-md-min: @screen-md;@screen-lg: 992px;@screen-lg-min: @screen-lg;@screen-xl: 1200px;@screen-xl-min: @screen-xl;@screen-xxl: 1600px;@screen-xxl-min: @screen-xxl;