@import '@/style/theme.less';

@pkg: avatar-popover;

.@{pkg} {
  color: #333 !important;

  .fle-popover-inner {
    background-color: transparent;

    .fle-popover-inner-content {
      padding: 0;
    }
  }

  &-content {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 352px;
    border-radius: 2px;
    box-shadow: 0 0 16px 0 #c9d6ed;
    padding: 24px 16px 16px;
    // background-color: #f4f7ff;
    background: linear-gradient(360deg, #f4f7ff 0%, #f4f7ff 100%);
    backdrop-filter: blur(2px);
    position: relative;
    overflow: hidden;
  }
  &-status {
    position: absolute;
    right: 0;
    top: -1px;
    width: 104px;
    height: 28px;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
    }
  }
  &-title {
    margin-bottom: 4px;
    font-size: 16px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 262px;
  }

  &-company {
    width: 100%;
  }

  &-certification-tag {
    border-radius: 2px;
    color: #fff;
    line-height: 1;
    display: inline-flex;
    align-items: center;
    padding: 3px 6px;
    cursor: pointer;
    font-size: 12px;

    .anticon {
      font-size: 14px;
      margin-right: 2px;
    }

    &.blue {
      background-color: @primary-color;
    }

    &.grey {
      background-color: #ddd;
    }

    &.greylight {
      background: rgb(98, 117, 174);
    }
    & + .avatar-popover-certification-tag {
      margin-left: 8px;
    }
  }

  &-user {
    background-color: #fff;
    border-radius: 2px;
    padding: 8px 16px;
    width: 100%;
    margin-top: 16px;
  }

  &-company-card {
    background-color: #fff;
    border-radius: 2px;
    padding: 8px;
    width: 100%;
    margin-top: 16px;

    &-title {
      font-weight: 500;
      margin-bottom: 8px;
    }
  }

  &-company-list {
    max-height: 224px;
    overflow-y: auto;

    &.fle-scroll:hover {
      &::-webkit-scrollbar-thumb,
      &::-webkit-scrollbar-track {
        display: block;
      }
    }
  }

  &-company-item {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f8f8f8;
    border-radius: 2px;
    padding: 8px 16px;
    width: 100%;
    margin-bottom: 8px;
    font-size: 14px;
    cursor: pointer;
    border: 1px solid #f8f8f8;

    &.active {
      border-color: @primary-color;
      background-color: @primary-color !important;
      color: #fff;
    }

    &:hover {
      background-color: #eff4ff;
      border-color: @primary-color;
    }

    &:last-child {
      margin-bottom: 0;
    }

    & > * {
      margin-left: 8px;
    }
  }

  &-company-name {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &-company-type {
    color: #ffab00;
  }

  &-company-change {
    cursor: pointer;
  }

  &-blcok-btn {
    margin-top: 16px;

    &:last-child {
      margin-top: 8px;
    }
  }
}
.login-tag {
  border-radius: 2px;
  padding: 0 8px;
  margin-right: 10px;
  font-size: 12px;
}
.login-main-tag {
  background: rgba(47, 74, 250, 0.1);
  color: @primary-color;
}
.login-gray-tag {
  background: rgba(0, 0, 0, 0.06);
  color: #333333;
}

.login-red-tag {
  background: #ff4d4f;
  color: white;
}

@primary-color: #2c68ff;@shadow-1-down: 0 6px 16px -8px rgba(0, 0, 0, 0.08), 0 9px 28px 0 rgba(0, 0, 0, 0.05),
  0 12px 48px 16px rgba(0, 0, 0, 0.03);@screen-xs: 480px;@screen-xs-min: @screen-xs;@screen-sm: 576px;@screen-sm-min: @screen-sm;@screen-md: 768px;@screen-md-min: @screen-md;@screen-lg: 992px;@screen-lg-min: @screen-lg;@screen-xl: 1200px;@screen-xl-min: @screen-xl;@screen-xxl: 1600px;@screen-xxl-min: @screen-xxl;